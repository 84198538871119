import React, { useState, useEffect, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import {isMobile} from 'react-device-detect';

import Spotify from '../components/Spotify'
import Button from '../components/Button'
import ProjectCard from '../components/ProjectCard'
import DigitalArtCard from '../components/DigitalArtCard'
import EtcCard from '../components/EtcCard'
import Headerbar from './Headerbar';

function Home(props) {
  const [mouseX, setMouseX] = useState(0);
  const waveRef = useRef(null);

  const [width, setWidth] = useState(0);

  const getMouseX = (e) => {
    setMouseX(e.clientX);
  };

  const getWidth = (e) => {
    if (waveRef.current) {
      setWidth(waveRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', getMouseX);
    window.addEventListener('resize', getWidth);
    getWidth();

    return () => {
      window.removeEventListener('mousemove', getMouseX);
      window.addEventListener('resize', getWidth);
    };
  }, []);

  const waveSpring = useSpring({
    translate: -(width*0.15)*(mouseX/window.innerWidth),
    config: {
      mass: 2,
      friction: 120,
      tension: 120,
    },
  })

  const Intro = () => {
      return <div className='w-full h-full flex flex-col'>
                <p className='text-blue whitespace-nowrap keplermed -mb-[1px] absolute top-0 hidden md:inline' style={{fontSize: '12px'}}>
                  Web/UX Development, Digital Art & Graphic Design
                </p>
                <div className='w-full h-full md:py-[1rem]'>
                  <div className='bg-blue mb-[1rem] min-h-[11px] max-h-[13px] h-[1vh]'/>
                  <div className='flex flex-col w-full h-full justify-start items-start'>
                    <img className='mb-[2rem] w-[95%] h-auto max-w-[400px]' src={require('../images/myname.png')} alt='my name'/>
                    <p className='text-blue w-[100%] mb-[2rem]'>
                      Welcome! I’m a digital artist and developer from <mark>Incheon, South Korea</mark> studying <mark>Computer Science</mark> and <mark>Art. Theory, and Practice</mark> at Northwestern University. 
                      <br/><br/>I love to bring my creative ideas to life on the screen by enhancing my digital art with graphic design and building fluid UI/UX interactions that dynamically fit every user.
                      <br/><br/>Here’s a look into some of my work so far. Stay awhile and enjoy!
                      <br/><br/>-
                    </p>
                    <img className='w-[40%] min-w-[150px] max-w-[250px] h-auto' src={require('../images/signature.png')} alt={'My Signature'}/>
                  </div>
                </div>
              </div>
    }
    
    function Waves() {
      return <div className='relative min-h-[600px] h-full'>
        <div className='relative top-0 w-full h-full mt-[1rem] min-h-[600px] overflow-hidden' style={{height: 'calc(100% - 1rem)'}}>
          <div className='absolute z-10 w-full h-full ml-[1rem] overflow-hidden'>
            <animated.div ref={waveRef} className='absolute top-0 mx-auto w-[120%] h-[90%]' style={waveSpring}>
              <img className='absolute top-0 left-0 w-full min-w-[800px] h-full object-fit object-top' src={require('../images/waves.png')} alt='Abstract Waves'/>
            </animated.div>
          </div>
          <div className='w-full h-full absolute mt-[1rem] right-[1rem] overflow-hidden'>
            <img className='object-cover min-w-full min-h-full' src={require('../images/sand.png')} alt='sand'/>
          </div>
        </div>

      <div className='absolute w-[1rem] aspect-square top-[-1rem] right-[-1rem] bg-blue'></div>
      <div className='absolute w-[1rem] aspect-square bottom-[-1rem] md:bottom-0 left-[-1rem] overflow-hidden'>
        <img className='object-contain' src={require('../images/sandsquare.png')} alt='sand'/>
      </div>
      </div>
    }

  return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full mt-auto text-blue' onMouseEnter={() => props.setHover({})}>
      {
        (window.innerWidth <= 768)
        ? <Headerbar {... props}/>
        : <div/>
      }

      <div className='my-auto flex flex-wrap justify-center items-center w-full h-fit lg:h-full lg:max-h-[1000px] p-[1.5rem] pt-[6rem] md:p-[3rem] xl:px-[6rem] space-y-[3rem] relative z-0 max-w-[2000px]'>
        <div className='flex mx-auto relative flex-col items-start xl:items-center xl:flex-row w-[100%] h-fit xl:h-full space-y-[2rem] xl:space-y-0 xl:space-x-[2rem]'>
          <div className='w-full xl:w-2/3 3xl:w-full h-full md:space-x-[1rem] flex flex-col-reverse justify-end xl:justify-start md:flex-row'>
            <div id='LEFT COL' className='relative justify-start w-full h-full xl:w-1/2 min-w-[300px] 3xl:min-w-[500px] xl:max-w-[900px] driftinfast'>
                <Intro/>
            </div>
            <div id='CENTER COL' className='relative justify-start w-full xl:w-1/2 3xl:w-full min-h-[600px] mb-[2rem] md:mb-0 driftinslow'>
              <div className='w-[12rem] driftinleft aspect-square md:absolute hidden md:block bottom-[1rem] 3xl:bottom-[2rem] z-50 right-[2rem] xl:left-[-6rem] 3xl:left-[-7rem] rounded-full'>
                <Spotify ReducedMotion = {props.ReducedMotion} setHover={props.setHover}/>
              </div>
              <Waves/>
            </div>
          </div>
          <div id='RIGHT COL' className='relative flex h-full xl:min-h-[600px] min-w-[300px] 3xl:min-w-[500px] w-full xl:w-1/3 xl:max-w-[900px] justify-between flex-col fadein xl:pb-0'>
            <div className='flex flex-col md:flex-row xl:flex-col space-x-0 md:space-x-[1rem] xl:space-x-0'>
              <div className='justify-start w-[100%] md:w-[50%] lg:w-[100%]'>
                <ProjectCard ReducedMotion = {props.ReducedMotion} setHover={props.setHover}/>
              </div>
              <div className='justify-start w-[100%] md:w-[50%] lg:w-[100%]'>
                <DigitalArtCard ReducedMotion = {props.ReducedMotion} setHover={props.setHover}/>
              </div>
            </div>
            <div>
              <div className='justify-start w-[100%] mb-[1rem]'>
                <EtcCard ReducedMotion = {props.ReducedMotion} setHover={props.setHover}/>
              </div>
              <div className='border-y-[3px] py-[4px] border-blue mb-[1rem]'>
                <div className='flex flex-row justify-between flex-wrap border-y-[1px] border-blue w-full py-[1rem]
                                text-blue keplersemi'>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://www.linkedin.com/in/tom-hojun-choi/'} download={false}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/linkedin.png')} alt='linkedin icon'/>
                    <div>LINKEDIN</div>
                  </Button>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://instagram.com/artbyhunch'} download={false}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/instagram.png')} alt='instagram icon'/>
                    <div>INSTAGRAM</div>
                  </Button>
                  <Button ReducedMotion = {props.ReducedMotion} link={'mailto:tomchoi2025@u.northwestern.edu'} download={false}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/mail.png')} alt='email me'/>
                    <div>EMAIL</div>
                  </Button>
                  <Button ReducedMotion = {props.ReducedMotion} link={require('../components/tomchoiresume.pdf')} download={true}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/download.png')} alt='download resume'/>
                    <div>RESUME</div>
                  </Button>
                </div>
              </div>
              {
                  (window.innerWidth <= 768 || isMobile)
                  ? <div/>
                  :
                  <div className='flex flex-wrap w-full'>
                    <div className='keplermed text-blue w-fit cursor-pointer relative z-50' onClick={()=>{props.toggleRM();}}>
                      <Button setHover={props.setHover}>
                        <div className='flex flex-row h-[1rem] justify-start items-center'>
                          <div className='p-[1px] w-[1rem] aspect-square border-blue border-[1px] mr-[0.5rem]'>
                            <div className='w-full h-full border-[1px] border-blue'
                              style={{backgroundColor: props.ReducedMotion ? 'rgba(42, 52, 151, 1)' : 'rgba(42, 52, 151, 0)', opacity: props.ReducedMotion ? 1 : 0.5}}/>
                          </div>
                          {`Reduced Motion: ${props.ReducedMotion ? 'ON' : 'OFF'}`}
                        </div>
                      </Button>
                    </div>
                    <div className='keplermed text-blue w-fit cursor-pointer relative z-50' onClick={()=>{props.toggleTextures();}}>
                      <Button setHover={props.setHover}>
                        <div className='flex flex-row h-[1rem] justify-start items-center'>
                          <div className='p-[1px] w-[1rem] aspect-square border-blue border-[1px] mr-[0.5rem]'>
                            <div className='w-full h-full border-[1px] border-blue'
                              style={{backgroundColor: !props.texturesOff ? 'rgba(42, 52, 151, 1)' : 'rgba(42, 52, 151, 0)', opacity: !props.texturesOff ? 1 : 0.5}}/>
                          </div>
                          {`Textures: ${props.texturesOff ? 'OFF' : 'ON'}`}
                        </div>
                      </Button>
                    </div>
                  </div>                
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Home;