export default function Heading(props) {
    return (<div className='w-full flex flex-row justify-center items-center'>
                <div className='flex whitespace-nowrap mr-[10px] keplersemi'>
                    {props.children}
                </div>
                <div className='w-full flex flex-col space-y-[4px]'>
                    <div className='h-[1px] bg-blue w-[100%]'/>
                    <div className='h-[1px] bg-blue w-[100%]'/>
                </div>
            </div>)
  }