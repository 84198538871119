import React from 'react'

import Button from './Button'

export default function ListElement(props) {
  return (
    <div className='relative h-[2rem] w-full'>
        <Button setHover = {props.setHover} link={props.link}  ReducedMotion = {props.ReducedMotion}>
            <hr className='border-blue border-dashed w-full mr-[0.5rem] ml-[1rem]'/>
            <div className='keplermed whitespace-nowrap text-sm'>
                {props.text}
            </div>
        </Button>
    </div>
  )
}
