import React from 'react'

import Button from './Button';

export default function DigitalArtCard(props) {
  return (
    <div className='w-full h-full flex flex-col justify-start items-start'>
        <div className='text-blue keplersemi justify-start items-center flex w-full space-x-[0.5rem]'>
          <div className='ml-[0.5rem]'>MISC.</div>
          <div className='h-[1px] w-full bg-blue'/>
        </div>
        <div className='flex flex-wrap h-full w-full border-b-[1px] border-blue space-y-[-0.5rem] pb-[0.25rem]'>
          <div className='-mt-[0.5rem]'>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/28mA5sAZInVnbXN3qj4e2L?si=148d992a64254284'><p className='keplermed'>Indie Playlist</p></Button>
          </div>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/5mkain641eyeNOQSCj5PYQ?si=8285db635e3a43ab'><p className='keplermed'>Old School Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/3YpuMMtCes4fVuOb6KR1ac?si=e82984392c72425d'><p className='keplermed'>Jazz Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/10yM1JrwgGg1gtKmYBl2XA?si=d41632dab2a44d40'><p className='keplermed'>City Pop Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/5T5L884pfD8qlXZnCAlLRA?si=b5132aa20cf7496a'><p className='keplermed'>Hiphop Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/6jh2iSONjc9MWDWhExJTtJ?si=bf8c7045b5774be7'><p className='keplermed'>Kpop Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://open.spotify.com/playlist/0TSyToWj4xYJxIVUYwFU88?si=e2f0481511a444a7'><p className='keplermed'>Instrumentals Playlist</p></Button>
          <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link='https://www.youtube.com/watch?v=9OVNG7JpnJA&list=WL&index=122'><p className='keplermed'>Greatest Video Of All Time</p></Button>
        </div>
    </div>
  )
}
