import React, { useState, useEffect } from 'react';
import Menu from '../components/Menu'
import Button from '../components/Button';

import { Waveform } from '@uiball/loaders';

import getSongItem from "../components/SpotifyAPI";

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const refreshToken = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN;

function Headerbar(props) {
    const [result, setResult] = useState({});

    useEffect(() => {
      const refreshSpotifyThings = () => {
        Promise.all([
          getSongItem(
            clientId,
            clientSecret,
            refreshToken
          ),
        ]).then((results) => {
          setResult(results[0]);
        });
      }

      refreshSpotifyThings();

      const i = setInterval(() => {
        refreshSpotifyThings();
      }, 5000);


      return () => clearInterval(i);    
    }, []);

    return ( 
      <div id='Top Bar with Name and Menu' className='fixed px-[0.5rem] py-[1.5rem] z-30 top-0 w-full h-fit left-0'>
        <div className='flex flex-row justify-center items-center w-full h-fit bg-white px-[1rem]'>
          <div className='relative z-50'>
            <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/'}>
              <div className='whitespace-nowrap text-blue keplerblack'>TOM CHOI</div>
            </Button>
          </div>
          <div className='relative z-10 w-full h-[1.2rem] overflow-hidden flex justify-start border-y-[1px] border-blue locked'>

            <p className='scroll keplersemi whitespace-nowrap flex flex-row leading-tight items-center justify-start'>
              <div>WELCOME!</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>WEB/UX DEVELOPMENT</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>DIGITAL ART & GRAPHIC DESIGN</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>HOPE YOU'RE HAVING A GREAT DAY ☺</div>
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              { result && <div className='uppercase'>{`CURRENTLY LISTENING TO: "${result.title}" by ${result.artist}`}</div>}
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>{'CHECK OUT MY OTHER WORK HERE -->'}</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
            </p>
            <p className='scroll keplersemi whitespace-nowrap flex flex-row leading-tight items-center justify-start'>
              <div>WELCOME!</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>WEB/UX DEVELOPMENT</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>DIGITAL ART & GRAPHIC DESIGN</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>HOPE YOU'RE HAVING A GREAT DAY ☺</div>
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              { result && <div className='uppercase'>{`CURRENTLY LISTENING TO: "${result.title}" by ${result.artist}`}</div>}
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>{'CHECK OUT MY OTHER WORK HERE -->'}</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
            </p>
            <p className='scroll keplersemi whitespace-nowrap flex flex-row leading-tight items-center justify-start'>
              <div>WELCOME!</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>WEB/UX DEVELOPMENT</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>DIGITAL ART & GRAPHIC DESIGN</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>HOPE YOU'RE HAVING A GREAT DAY ☺</div>
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              { result && <div className='uppercase'>{`CURRENTLY LISTENING TO: "${result.title}" by ${result.artist}`}</div>}
              { result && <div className='h-[0.5rem] w-fit overflow-hidden mx-[0.5rem]'><Waveform size={20} lineWeight={2.5} color={'#2A3497'}/></div>}
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
              <div>{'CHECK OUT MY OTHER WORK HERE -->'}</div>
              <div className='mx-[0.5rem]'><div className='w-[0.3rem] aspect-square bg-blue'></div></div>
            </p>

          </div>
          <div className='ml-[-1rem] relative z-0 pl-[0.3rem]'>
            <Menu {... props}/>
          </div>
        </div>
      </div>
     );
}

export default Headerbar;