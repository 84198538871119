import React, { useState, useEffect, useRef } from 'react';

import Tag from '../components/Tag';
import Button from '../components/Button';

import Headerbar from './Headerbar'

function Portfolio(props) {
  const bannerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const Row = (props) => {
    return(<div className='border-t-[1px] border-blue p-[0.5rem] w-full h-fit keplersemi flex flex-row space-x-[0.5rem]'>
      {props.children}
    </div>)
  }

  useEffect(() => {
    const bannerHeight = () => {
      if (bannerRef.current && bannerRef.current.getBoundingClientRect().height !== height) {
        let h = bannerRef.current.getBoundingClientRect().height;
        setHeight(h)
      }
    }

    bannerHeight();
    window.addEventListener('resize', bannerHeight);
    setInterval(()=>{
      bannerHeight();
    }, 500)

    return () => {
      window.removeEventListener('resize', bannerHeight);
    }
  }, []);

    return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full text-blue pt-[4.5rem]'
      onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto w-full h-fit lg:max-h-[1000px] p-[1.5rem] md:p-[3rem] xl:px-[6rem] relative z-0 max-w-[2000px]'>
        <div className='min-h-fit h-full w-full flex flex-col md:flex-row items-start justify-start pb-[2rem] md:pb-0'>
          <div id='Banner' ref={bannerRef} className='w-full lg:w-1/2 xl:w-1/3 3xl:min-w-[500px] h-fit mb-[2rem] md:mb-0 md:mr-[1rem] fadein'>
            <div className='lg:max-w-[800px] driftinfast relative left-0 flex flex-col justify-start items-start h-fit min-h-full'>
              <a className='keplersemi text-blue w-fit inline md:hidden mb-[2rem]' href='/projects'>
              <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>


              <div className='w-full h-full flex flex-col justify-start space-y-[1rem]'>
                <div>
                  <img className='mb-[1rem] w-[100%] h-auto max-w-[450px]' src={require('../images/projects/portfolio/title.png')} alt='olympus'/>
                  <div className='flex w-full h-fit py-[0.5rem] justify-start flex-wrap'>
                    <Tag text={'Branding'}/>
                    <Tag text={'Web Design'}/>
                    <Tag text={'Website Development'}/>
                  </div>
                </div>

                <div className='w-full flex justify-start items-start'>
                  <p className='min-w-[300px] text-justify'>
                    While this is not my first web portfolio, it is the first one where I really took the time to build a unique cohesive personal brand. I wanted a brand identity that reflected my philosophy as a web designer, as well as my passion for digital art and various genres of music. Based on this I built a bright, textured visual aesthetic reminiscent of sand and waves, which also captured my close ties to the coast in both Incheon and Chicago. 
                    <br/><br/>I also wanted to apply my learning in web development to the maximum, exploring features like a more dynamic layout that feels comfortable on any screen, a box-cursor that wraps around clickable DOM elements, integration with the Spotify API, building scroll-reactive image galleries, and more.
                  </p>
                </div>

                <div className='w-full text-blue keplersemi'>
                  <div className='flex w-full h-fit pt-0 justify-start items-start flex-col text-blue keplermed'>
                    <Row>
                      <div className='min-w-[8rem]'>DURATION</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>Jun 2023 ~ Sep 2023</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='min-w-[8rem]'>SKILLS<br/>DEVELOPED</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>1) Forming a cohesive personal brand</p>
                        <p>2) Building reactive website layouts</p>
                        <p>3) Managing API access with Spotify</p>
                        <p>4) Reduced-motion accessibility options in UX</p>
                      </div>
                    </Row>
                  </div>
                </div>

                <div className='w-full flex justify-start items-center keplersemi'>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://github.com/choi-choi-train/spacebars'} download={true}
                    setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/github.png')} alt='github repository'/>
                    <div>GITHUB</div>
                  </Button>
                </div>

                <a className='keplersemi text-blue w-fit hidden md:inline' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
                </a>
              </div>
            </div>
          </div>

          <div id='scroll' className='relative w-full lg:w-1/2 xl:w-2/3 3xl:w-full flex flex-row z-0 justify-start items-start pb-[2rem] md:pb-[1rem] md:pl-[1rem]' 
            style={{height: (window.innerWidth > 768) ? height : 'fit'}}>

            <img className='driftinleft hidden md:inline absolute -z-10 w-[100%] h-[100%] object-cover right-[1.5rem] top-[1rem] pl-[1.5rem] pb-[1rem]' src={require('../images/sand.png')} alt='gradient background'/>

            <div className='w-full h-fit md:h-full flex flex-col space-y-[0.5rem] justify-start items-start md:overflow-y-scroll'>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  BRAINSTORMING
                </div>
              </div>
              <img className='w-full h-auto object-top' src={require('../images/projects/portfolio/brainstorming.png')} alt='fintech figma'/>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  FIGMA MOCKUP
                </div>
              </div>
              <img className='w-full h-auto object-top' src={require('../images/projects/portfolio/figma_1.png')} alt='fintech figma'/>

              <a className='keplersemi text-blue w-fit lg:hidden my-[2rem]' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Headerbar {... props}/>
    </div> );
}

export default Portfolio;