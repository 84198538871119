import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import '../src/App.css';

import Home from './pages/Home';
import Projects from './pages/Projects';
import DigitalArt from './pages/DigitalArt';
import Olympus from './pages/Olympus';
import NUFT from './pages/NUFT';
import Portfolio from './pages/Portfolio';
import Spacebars from './pages/Spacebars';
import Chatapp from './pages/Chatapp';
import Piece from './pages/Piece';

import Cursor from './components/Cursor'

function App() {
  const [ReducedMotion, setReducedMotion] = useState(()=>{
    const data = localStorage.getItem('REDUCED_MOTION');
    if (isMobile) {
      return true;
    } else {
      return (data ? JSON.parse(data) : false);
    }
  });

  const [texturesOff, setTexturesOff] = useState(()=>{
    const data = localStorage.getItem('TEXTURES_OFF');
    if (isMobile) {
      return true;
    } else {
      return (data ? JSON.parse(data) : false);
    }
  });

  const [hover, setHover] = useState({});

  useEffect(() => {
    const handleStorage = (e) => {
      if (e.key === 'REDUCED_MOTION') {
        setReducedMotion(JSON.parse(e.newValue));
      }
      if (e.key === 'TEXTURES_OFF') {
        setTexturesOff(JSON.parse(e.newValue));
      }
    };

    window.addEventListener('storage', handleStorage);

    return window.removeEventListener('storage', handleStorage);
  }, []);

  const toggleRM = () => {
    const bool = !ReducedMotion
    setReducedMotion(bool);
    localStorage.setItem('REDUCED_MOTION', JSON.stringify(bool))
    window.location.reload();
  }
  const toggleTextures = () => {
    const bool = !texturesOff
    setTexturesOff(bool);
    localStorage.setItem('TEXTURES_OFF', JSON.stringify(bool))
  }

  return (
    <div id='main-container'>
      {
        !isMobile
        && <Cursor hover={hover} ReducedMotion = {ReducedMotion}/>
      }
    
      <BrowserRouter>
        {
          !isMobile
          &&
          <img className='locked w-[100vw] h-[100vh] fixed top-0 left-0 opacity-[40%] z-40' src={require('./images/noise.png')} style={{display: texturesOff ? 'none' : null, mixBlendMode:'hard-light'}} alt='decorative'/>
        }
        {
          !isMobile
          &&
          <img className='locked w-[100vw] h-[100vh] fixed top-0 left-0 opacity-[75%] z-40' src={require('./images/texture_1.png')} style={{display: texturesOff ? 'none' : null, mixBlendMode:'color-dodge'}} alt='decorative'/>
        }
        <Routes>
          <Route path='/*' element={<Home setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects' element={<Projects setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects/olympus' element={<Olympus setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects/nuft' element={<NUFT setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects/portfolio' element={<Portfolio setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects/spacebars' element={<Spacebars setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/projects/chatapp' element={<Chatapp setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/digitalart' element={<DigitalArt setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
          <Route path='/digitalart/*' element={<Piece setHover={setHover} ReducedMotion={ReducedMotion} toggleRM={toggleRM} toggleTextures={toggleTextures} texturesOff={texturesOff}/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
 
export default App;