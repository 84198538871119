import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from "react-router";
import {isMobile} from 'react-device-detect';
import { useNavigate } from "react-router-dom";

import Frame from '../components/Frame';
import PieceDescription from '../components/PieceDescription'
import Button from '../components/Button';

import Headerbar from './Headerbar';

import wawh from '../images/compressedart/waves&whiskers.png';
import fofu from '../images/compressedart/form+function.png';
import dct from '../images/compressedart/dreamscometrue.png';
import exp from '../images/compressedart/explore.png';
import ftwo from '../images/compressedart/formulatwo.png';
import xsub from '../images/compressedart/extrasubstance.png';
import idfn from '../images/compressedart/ildolcefarniente.png';
import brth from '../images/compressedart/breather.png';
import nbdm from '../images/compressedart/nobodymove.png';
import mlsp from '../images/compressedart/moonlightsupermarket.png';
import bfre from '../images/compressedart/brainfreeze.png';

export default function DigitalArt(props) {
  const gallery = useRef(null);
  const descriptionsRef = useRef();
  const [initialScroll, setInitialScroll] = useState(true);
  const [short, setShort] = useState('')
  var PieceList = [
    {position: 1, link: wawh, title:'Waves & Whiskers', short:'wawh', date:'9.5.2023', tags:['Procreate'], post:'https://www.instagram.com/p/CwzOw9arnDg/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CwzKIRLL-Rk/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 2, link: xsub, title:'Extra Substance', short:'xsub', date:'10.5.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Cx-6Ch5yIHd/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Cx-4D6LStOu/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 3, link: fofu, title:'Form + Function', short:'fofu', date:'6.25.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Ct6f4L5rXFc/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Ct6drHdJieh/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 4, link: dct, title:'Dreams Come True', short:'dct', date:'7.23.2023', tags:['Procreate', 'Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/CvCg_9ZrvgN/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CvCfzfGtS_f/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 5, link: ftwo, title:'Formula 2', short:'ftwo', date:'9.21.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/CxdJCMDr68r/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
    {position: 6, link: brth, title:'Breather', short:'brth', date:'10.9.2023', tags:['Procreate', 'Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/CyLI69dyoNR/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CyJQkxSSuF7/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 7, link: exp, title:'Explore', short:'exp', date:'8.15.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Cv9uM3NL_hR/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Cv9s92ltVNL/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 8, link: bfre, title:'Brain Freeze', short:'bfre', date:'8.15.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/CvxPFfFLSpk/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
    {position: 9, link: idfn, title:'Il Dolce Far Niente', short:'idfn', date:'5.1.2023', tags:['Procreate'], post:'https://www.instagram.com/p/CrwFG1Mu1dw/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CrrKyGAsm8K/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    {position: 10, link: nbdm, title:'Nobody Move!', short:'nbdm', date:'9.5.23', tags:['Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/Cwz9_3Crwp1/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
    {position: 11, link: mlsp, title:'Moonlight Supermarket', short:'mlsp', date:'3.20.2023', tags:['Procreate'], post:'', reel:'https://www.instagram.com/reel/CqAYTZluLCz/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
  ]

  const location = useLocation();
  const [height, setHeight] = useState(0);

  var galleryHeight = ()=>{
    if (gallery.current) {
      return (window.innerWidth > 768) ? height : 600;
    }
  }

  const [Focus, setFocus] = useState(1);

  const galleryScroll = (position) => {
    if (gallery.current) {
      console.log(`scroll to ${position} from ${Focus}. galleryheight = ${galleryHeight()}`)
      gallery.current.scrollBy({top: galleryHeight() * (position - Focus), behavior: props.ReducedMotion || initialScroll? 'instant' : 'smooth'});
      setFocus(position);
      setShort(PieceList[Focus - 1].short);
    }
  };

  const updateShort = (position) => {
    setShort(PieceList[position - 1].short);
  }

  useEffect(() => {    
    const sizeGallery = () => {
      if (descriptionsRef.current) {
        if (descriptionsRef.current.getBoundingClientRect().height !== height) {
          let h = descriptionsRef.current.getBoundingClientRect().height;
          setHeight(h);
        } else if (initialScroll) {
          let res = location.state;
          if (res) {
  
            galleryScroll(res.focuson);
          }
          setInitialScroll(false);
  
        }
      }
    }

    window.addEventListener('resize', sizeGallery);
    sizeGallery();
    setTimeout(()=>sizeGallery(), 1000);

  }, [height, descriptionsRef.current]);

  return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full text-blue pt-[4.5rem]'
      onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto flex-wrap justify-center items-center w-full min-h-fit h-fit md:h-full max-h-[1000px] p-[1.5rem] md:p-[3rem] xl:px-[6rem] relative z-0 max-w-[2000px]'>
        <div className='h-fit w-full flex flex-col md:flex-row items-start justify-start'>
          <div id='piecedescriptions' ref={descriptionsRef} className='w-full min-w-[300px] md:w-1/2 xl:w-1/3 h-fit md:relative flex flex-col justify-between items-start mb-[2rem] md:mb-0 mr-0 md:mr-[1.5rem]'>
            <div className='flex flex-col h-full md:h-fit items-start justify-center space-y-[1rem]'>
              <img className='mb-[1rem] w-[95%] h-auto max-w-[300px]' src={require('../images/digitalarttitle.png')} alt='digital art'/>
              <p className='text-justify'>
                While I've been drawing digitally for a while, recently I've begun to experiment with various ideas and styles, also applying my knowledge of Photoshop and Illustrator to add another layer of finish to some pieces.
              </p>
            </div>
            <div className='w-full h-fit hidden md:flex flex-col items-start justify-center mt-[2rem]'>
            {
              PieceList.map((piece)=>{
                return(
                    <PieceDescription ReducedMotion = {props.ReducedMotion} galleryScroll={galleryScroll} setHover={props.setHover} focus={Focus} 
                    setFocus={setFocus} piece={piece}/>
                )
              }) 
            }
            </div>
          </div>

          <div id='galleryBox' className='w-full md:w-1/2 xl:w-2/3 3xl:w-full z-10 relative'>
            <div className='w-[1rem] aspect-square absolute top-[-1rem] right-[-0.5rem] hidden md:inline'/>
            <div className='relative w-full h-full hidden md:inline'>
              <Button {... props} link={`/digitalart/${short}`}>
                <div id='scroll' ref={gallery} className='will-change-scroll w-full relative z-0 flex flex-wrap justify-center items-start overflow-y-scroll snap-y snap-mandatory'
                style={{height: height}}>
                {
                  PieceList.map((piece)=>{
                    return(
                        <Frame ReducedMotion = {props.ReducedMotion} setHover={props.setHover} focus={Focus} galleryScroll = {galleryScroll}
                        setFocus={setFocus} piece={piece} updateShort={updateShort} initialScroll={initialScroll}/>
                    )})
                }
                </div>
              </Button>
              <div className='driftinleft absolute w-full h-full right-[2rem] pl-[1.5rem] top-[1.5rem] pb-[1rem]'>
                <img className='w-full h-full object-cover' src={require('../images/sand.png')} alt='sand'/>
              </div>
            </div>
            <div id='scroll' className='will-change-scroll w-full relative z-0 flex md:hidden flex-wrap justify-center items-start'
            style={{height: '600px'}}>
            {
              PieceList.map((piece)=>{
                return(
                    <Frame ReducedMotion = {props.ReducedMotion} setHover={props.setHover} focus={Focus} galleryScroll = {galleryScroll}
                    setFocus={setFocus} piece={piece} updateShort={updateShort}/>
                )})
            }
            </div>
          </div>
        </div>
      </div>
    <Headerbar {... props}/>
    </div>
  )
}
