import React, { useState, useEffect, useRef } from 'react';

import Tag from '../components/Tag';
import Button from '../components/Button';

import Headerbar from './Headerbar'

function Spacebars(props) {
  const bannerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const Row = (props) => {
    return(<div className='border-t-[1px] border-blue p-[0.5rem] w-full h-fit keplersemi flex flex-row space-x-[0.5rem]'>
      {props.children}
    </div>)
  }

  useEffect(() => {
    const bannerHeight = () => {
      if (bannerRef.current && bannerRef.current.getBoundingClientRect().height !== height) {
        let h = bannerRef.current.getBoundingClientRect().height;
        setHeight(h)
      }
    }

    bannerHeight();
    window.addEventListener('resize', bannerHeight);
    setInterval(()=>{
      bannerHeight();
    }, 500)

    return () => {
      window.removeEventListener('resize', bannerHeight);
    }
  }, []);

    return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full text-blue pt-[4.5rem]'
      onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto w-full h-fit lg:max-h-[1000px] p-[1.5rem] md:p-[3rem] xl:px-[6rem] relative z-0 max-w-[2000px]'>
        <div className='min-h-fit h-full w-full flex flex-col md:flex-row items-start justify-start pb-[2rem] md:pb-0'>
          <div id='Banner' ref={bannerRef} className='w-full lg:w-1/2 xl:w-1/3 3xl:min-w-[500px] h-fit mb-[2rem] md:mb-0 md:mr-[1rem] fadein'>
            <div className='lg:max-w-[800px] driftinfast relative left-0 flex flex-col justify-start items-start h-fit min-h-full'>
              <a className='keplersemi text-blue w-fit inline md:hidden mb-[2rem]' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>

              <div className='w-full min-h-full h-fit flex flex-col justify-start items-start space-y-[1rem]'>
                <div>
                  <img className='mb-[1rem] w-[100%] h-auto max-w-[500px]' src={require('../images/projects/spacebars/title.png')} alt='space bars'/>
                  <div className='flex w-full h-fit py-[0.5rem] justify-start flex-wrap'>
                    <Tag text={'Game Development'}/>
                    <Tag text={'UI/UX Design'}/>
                    <Tag text={'Web Design'}/>
                  </div>
                </div>

                <div className='w-full flex justify-start items-start'>
                  <p className='min-w-[300px] text-justify'>
                    <mark>Space Bars</mark> is a <mark>top-down space arcade game</mark> where the player controls a ship to dodge lasers
                    floating around the screen, collecting energy pellets to restore health and get high scores.
                    I came up with the idea for it while following a tutorial on Javascript canvas, as I wondered
                    what I could do to apply my learning to my knowledge of React. During the process I engaged in
                    <mark> UX testing with family and friends,</mark> through which I gained many insights into the psychology of game development.
                  </p>
                </div>

                <div className='w-full text-blue keplersemi'>
                  <div className='flex w-full h-fit pt-0 justify-start items-start flex-col text-blue keplermed'>
                    <Row>
                      <div className='min-w-[8rem]'>DURATION</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>Sep 14 2023 ~ Sep 20 2023</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='min-w-[8rem]'>SKILLS<br/>DEVELOPED</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>1) Building particle-based animations on Javascript canvas</p>
                        <p>2) Advanced React rendering & state management with JS canvas</p>
                        <p>3) Object-based game development</p>
                        <p>4) Game balance & UI/UX research</p>
                        <p>5) Google Firestore backend integration</p>
                      </div>
                    </Row>
                  </div>
                </div>

                <div className='flex w-full keplersemi justify-start items-center'>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://space-bars.web.app'} download={false}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/link.png')} alt='visit website'/>
                    <div>VISIT WEBSITE</div>
                  </Button>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://github.com/choi-choi-train/spacebars'} download={true}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/github.png')} alt='github repository'/>
                    <div>GITHUB</div>
                  </Button>
                </div>

                <a className='keplersemi text-blue w-fit hidden md:inline my-[2rem]' href='/projects'>
                  <Button {... props}>
                    <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                      <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                      <div className='pt-[3px]'>{'Back to Projects'}</div>
                    </div>
                  </Button>
                </a>

              </div>
            </div>
          </div>

          <div id='scroll' className='relative w-full lg:w-1/2 xl:w-2/3 3xl:w-full flex flex-row z-0 justify-start items-start pb-[2rem] md:pb-[1rem] md:pl-[1rem]' 
            style={{height: (window.innerWidth > 768) ? height : 'fit'}}>
            <img className='driftinleft hidden md:inline absolute -z-10 w-[100%] h-[100%] object-cover right-[1.5rem] top-[1rem] pl-[1.5rem] pb-[1rem] opacity-50' src={require('../images/spacebarsbg.png')} alt='gradient background'/>

            <div className='w-full h-fit md:h-full flex flex-col space-y-[0.5rem] justify-start items-start md:overflow-y-scroll'>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  MENU PAGE SCREENSHOTS
                </div>
              </div>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_1.png')} alt='space bars website ui screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_2.png')} alt='space bars website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_3.png')} alt='space bars website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_7.png')} alt='space bars website screenshot'/>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  IN-GAME SCREENSHOTS
                </div>
              </div>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_4.png')} alt='space bars website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/spacebars/screenshot_5.png')} alt='space bars website screenshot'/>
              <div className='flex w-full h-fit items-start justify-start space-x-[0.5rem] pr-[0.5rem]'>
                <img className='w-1/2 h-auto object-contain' src={require('../images/projects/spacebars/screenshot_6.png')} alt='space bars website screenshot'/>
                <img className='w-1/2 h-auto object-contain' src={require('../images/projects/spacebars/screenshot_8.png')} alt='space bars website screenshot'/>
              </div>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  INSTAGRAM STORY PROMOTION
                </div>
              </div>
              <div className='flex w-full justify-start items-start space-x-[0.5rem] pr-[1rem]'>
                <img className='w-1/3 h-auto object-contain' src={require('../images/projects/spacebars/story_1.png')} alt='space bars story promotion screenshot'/>
                <img className='w-1/3 h-auto object-contain' src={require('../images/projects/spacebars/story_2.png')} alt='space bars story promotion screenshot'/>
                <img className='w-1/3 h-auto object-contain' src={require('../images/projects/spacebars/story_3.png')} alt='space bars story promotion screenshot'/>
              </div>
              <div className='flex w-full justify-start items-start space-x-[0.5rem] pr-[1rem]'>
                <img className='w-1/3 h-auto object-contain' src={require('../images/projects/spacebars/story_4.png')} alt='space bars story promotion screenshot'/>
                <img className='w-1/3 h-auto object-contain' src={require('../images/projects/spacebars/story_5.png')} alt='space bars story promotion screenshot'/>
              </div>

              <a className='keplersemi text-blue w-fit lg:hidden my-[2rem]' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Headerbar {... props}/>
    </div> );
}

export default Spacebars;