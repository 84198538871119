import React from 'react'
import { useNavigate } from "react-router-dom";

import Heading from './Heading';
import Button from './Button';
import ListElement from './ListElement';

export default function DigitalArtCard(props) {
  const navigate = useNavigate();

  return (
    <div className='w-full h-full mb-[1rem]'>
        <Heading>
            <Button setHover={props.setHover} link={'/digitalart'} ReducedMotion = {props.ReducedMotion}>
                <div className='text-blue keplersemi text-[24px]'>DIGITAL ART</div>
            </Button>
        </Heading>
        
        <a href='/digitalart/wawh'>
          <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Waves & Whiskers'}/>
        </a>
        <a href='/digitalart/xsub'>
          <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Extra Substance'}/>
        </a>
        <a href='/digitalart/fofu'>
          <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Form + Function'}/>
        </a>
        <a href='/digitalart/dct'>
          <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Dreams Come True'}/>
        </a>
        <a href='/digitalart'>
          <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'SEE MORE ...'}/>
        </a>
    </div>
  )
}
