import React, { useState, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import Tag from './Tag';
import Button from './Button'

function Frame(props) {
    const Pieceref = useRef(null);
    const [intersecting, setIntersecting] = useState(false);
    // const [mouseX, setMouseX] = useState(0);
    // const [mouseY, setMouseY] = useState(0);
    // const [offsetX, setOffsetX] = useState(0);
    // const [offsetY, setOffsetY] = useState(0);
    // const [mouseDown, setMouseDown] = useState(0);

    const imageSpring = useSpring({
        opacity: props.ReducedMotion ? 1 : intersecting && !props.initialScroll ? 1 : 0.1,
        paddingTop: props.ReducedMotion ? '0' : intersecting && !props.initialScroll ? '0' : '2rem',
        paddingBottom: props.ReducedMotion ? '0' : intersecting && !props.initialScroll ? '0' : '2rem',
        config: {
            mass: 1,
            friction: 60,
            tension: 180,
        },
    })

    //INSERSECTION OBSERVER
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setIntersecting(true);
                props.setFocus(props.piece.position);
                props.updateShort(props.piece.position);
            } else {
                setIntersecting(false);
            }
        }, {rootMargin: '0px 0px -30% 0px', threshold: 0.3}
      )
        observer.observe(Pieceref.current);
    }, []);

    return (
    <div ref={Pieceref} className='relative w-full h-fit md:h-full snap-center mb-[1rem] md:mb-[2rem] overflow-hidden'>
        <div className='w-full h-fit md:hidden keplersemi border-y-[3px] py-[4px] border-blue z-30'>
            <div className='bg-white border-y-[1px] border-blue w-full py-[0.5rem] pr-[0.5rem] flex flex-wrap items-center justify-between'>
                <div className='whitespace-nowrap'>{`${props.piece.title}`}</div>
                <div className='w-fit h-full flex flex-wrap justify-end items-end mr-[0.5rem] space-x-[0.3rem] space-y-[0.3rem]'>
                    <div className='flex w-fit h-fit justify-end items-start flex-wrap'>
                    {
                    props.piece.tags.map((tag)=>{return(<Tag text={tag}/>)})
                    }
                    </div>
                    <div className='keplermed whitespace-nowrap'>{props.piece.date}</div>
                </div>
            </div>
        </div>
        {
            <animated.div className='w-full h-full max-h-full flex flex-col justify-center items-center relative z-20' style={imageSpring}>
                <img className={`h-full w-full object-cover ${intersecting && !props.ReducedMotion ? 'objectdrift' : null}`} src={props.piece.link} alt={props.piece.title}/>
            </animated.div>
        }
        <div className='w-full h-fit md:hidden flex flex-row justify-start items-center keplersemi'>
        {
            props.piece.post !== ''
            &&
            <Button {... props} link={props.piece.post}>
                <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/link.png')} alt='visit website'/>
                <div>VIEW POST</div>
            </Button>
        }
        {
            props.piece.reel !== ''
            &&
            <Button {... props} link={props.piece.reel}>
                <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/link.png')} alt='visit website'/>
                <div>WATCH REEL</div>
            </Button>
        }
        </div>
    </div>
    );
}

export default Frame