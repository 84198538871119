import React from 'react'

export default function Tag(props) {
  return (
    <p className='py-[2px] px-[4px] text-blue border-blue border-[1px] border-solid m-[0.2rem] text-[12px]'
      style={{color: props.type === 'white' ? '#FBF3EB' : null, borderColor: props.type === 'white' ? '#FBF3EB' : null}}>
      {props.text}
    </p>
  )
}
