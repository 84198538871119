import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Tag from './Tag';
import Button from './Button';
import Heading from './Heading';

function PieceDescription(props) {
  const [Selected, setSelected] = useState(false);
    const heightSpring = useSpring({
      height: Selected ? '7rem' : '0.5rem',
      opacity: Selected ? '100%' : '50%',
      paddingTop: Selected ? 4 : 0,
      paddingBottom: Selected ? 4 : 0,
      borderTopWidth: Selected ? 3 : 1,
      borderBottomWidth: Selected ? 3 : 1,
      config: {
        mass: props.ReducedMotion ? 0 : 0.1,
        tension: 400,
        friction: 20,
      }
    })
    const widthSpring = useSpring({
      width: Selected ? '100%' : '0%',
      config: {
        mass: props.ReducedMotion ? 0 : 0.1,
        tension: 400,
        friction: 20,
      }
    })

    useEffect(() => {
      if (props.focus === props.piece.position) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }, [props.focus]);
  
    return ( 
      <div className='h-full w-full my-[0.5rem]'>
        <div className='relative w-full flex items-center justify-center h-fit my-[0.3rem]'>
          <div className='z-10 left-[-0.5rem] w-fit absolute'
            style={{transform: Selected ? `translate(0px, -1rem)` : `translate(0px, 0rem)`, transition: !props.ReducedMotion && '200ms ease-out',
              backgroundColor: Selected ? 'rgba(251, 243, 235, 0)' : 'rgba(251, 243, 235, 1)'}}>
            <div onClick={()=>{props.galleryScroll(props.piece.position)}}>
              <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover}>
                <div className='text-blue keplersemi text-[18px] whitespace-nowrap'>{props.piece.title}</div>
              </Button>
            </div>
          </div>
          
          <animated.div className='z-0 w-full border-y-blue overflow-hidden justify-center items-center min-h-fit'
            style={heightSpring}>
            <animated.div className='w-full border-y-blue border-y-[1px] h-full overflow-hidden flex justify-start items-end pb-[0.5rem]' style={widthSpring}>
              <div className='flex w-full h-fit justify-start items-center'>
                <div className='flex w-fit h-fit pb-[0.5rem] justify-start items-start'>
                {
                  props.piece.tags.map((tag)=>{return(<Tag text={tag}/>)})
                }
                </div>
              </div>
              <div className='w-full h-full pt-[0.5rem] flex justify-end items-center'>
                <Button {... props} link={`/digitalart/${props.piece.short}`}>
                  <div className='flex flex-wrap items-center'>
                    <div className='keplersemi whitespace-nowrap mr-[0.5rem]'>VIEW PIECE</div>
                    <img className='h-[1rem] aspect-square -rotate-180' src={require('../images/dropdown.png')} alt='see full piece'/>
                  </div>
                </Button>
              </div>
            </animated.div>
          </animated.div>
        </div>
      </div>
    );
  }

export default PieceDescription;
