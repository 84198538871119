import React, { useRef, useState, useEffect } from 'react'

export default function Button(props) {
  const thisButtonRef = useRef(null);
  const [width, setWidth] = useState(0); 
  const [height, setHeight] = useState(0); 
  const [x, setX] = useState(0); 
  const [y, setY] = useState(0); 

  const setEverything = () => {
    if (thisButtonRef.current) {
      setWidth(thisButtonRef.current.getBoundingClientRect().width);
      setHeight(thisButtonRef.current.getBoundingClientRect().height);
      setX(thisButtonRef.current.getBoundingClientRect().left);
      setY(thisButtonRef.current.getBoundingClientRect().top);  
    }
  }

  useEffect(() => {  
    const handleScroll = () => {
      setEverything();
    }

    window.addEventListener('resize', setEverything);
    window.addEventListener('mousemove', handleScroll);
    window.addEventListener('wheel', handleScroll);
    
    handleScroll();

    return () => {
      window.removeEventListener('resize', setEverything);
      window.removeEventListener('mousemove', handleScroll);
      window.removeEventListener('wheel', handleScroll);
    };
}, [])

  if (props.download === true) {
    return (
      <a ref={thisButtonRef} href={props.link} className='relative flex justify-center items-center z-50 py-[8px] px-[8px] hovercolor cursor-pointer' download
      onMouseEnter={() => props.setHover({positionX: x, positionY: y, width: width, height: height})}
      onMouseLeave={() => props.setHover({})} style={{cursor:'pointer'}}>
        {props.children}
      </a>
    )
  } else {
    return (
      <a ref={thisButtonRef} href={props.link} className='relative flex justify-center items-center z-50 py-[8px] px-[8px] hovercolor cursor-pointer'
      onMouseEnter={() => props.setHover({positionX: x, positionY: y, width: width, height: height})}
      onMouseLeave={() => props.setHover({})} style={{cursor:'pointer', width: (props.type === 'full' ? '100%' : 'fit'), height: (props.type === 'full' ? '100%' : 'fit')}}>
        {props.children}
      </a>
    )
  }
}
