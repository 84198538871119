import querystring from "querystring";
import { Buffer } from 'buffer';

const NOW_PLAYING_ENDPOINT = `https://api.spotify.com/v1/me/player/currently-playing`;
const RECENTLY_PLAYED_ENDPOINT = `https://api.spotify.com/v1/me/player/recently-played`;
const TOKEN_ENDPOINT = `https://accounts.spotify.com/api/token`;

const client_id = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const client_secret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const refresh_token = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN;

const getAccessToken = async () => {
  const basic = Buffer.from(`${client_id}:${client_secret}`).toString("base64");

  const response = await fetch(TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Basic ${basic}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: querystring.stringify({
      grant_type: "refresh_token",
      refresh_token,
    }),
  });

  return response.json();
};

export const getNowPlaying = async (client_id, client_secret, refresh_token) => {
  const { access_token } = await getAccessToken(
    client_id,
    client_secret,
    refresh_token
  );

  return fetch(NOW_PLAYING_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const getRecentlyPlayed = async (client_id, client_secret, refresh_token) => {
  const { access_token } = await getAccessToken(
    client_id,
    client_secret,
    refresh_token
  );

  return fetch(RECENTLY_PLAYED_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export default async function getSongItem(
  client_id,
  client_secret,
  refresh_token
) {
  var albumImageUrl;
  var artist;
  var songUrl;
  var title;

  const response = await getNowPlaying(client_id, client_secret, refresh_token);

  if (response.status === 204 || response.status > 400) {
    const newResponse = await getRecentlyPlayed(client_id, client_secret, refresh_token);

    if (newResponse.status === 204 || newResponse.status > 400) {
      return false;
    }
  
    const song = await newResponse.json();
    albumImageUrl = song.items[0].track.album.images[0].url;
    artist = song.items[0].track.artists.map((a) => a.name).join(", ");
    songUrl = song.items[0].track.external_urls.spotify;
    title = song.items[0].track.name;    
  
    return {
      albumImageUrl,
      artist,
      songUrl,
      title,
    };  
  }

  const song = await response.json();
  albumImageUrl = song.item.album.images[0].url;
  artist = song.item.artists.map((a) => a.name).join(", ");
  songUrl = song.item.external_urls.spotify;
  title = song.item.name;    

  return {
    albumImageUrl,
    artist,
    songUrl,
    title,
  };
}