import React from 'react'

import Headerbar from './Headerbar';

import Tag from '../components/Tag';
import Button from '../components/Button';
import Heading from '../components/Heading';

export default function Projects(props) {
  return (
    <div className='relative w-full flex flex-col justify-start items-center h-full mt-auto text-blue pt-[4.5rem]'
        onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto flex-wrap justify-center items-center w-full h-fit md:max-h-[840px] p-[1.5rem] relative z-0 max-w-[2000px]'>
          <div className='relative z-40 w-full h-fit flex flex-col lg:flex-row justify-center items-start'>
              <div className='flex flex-col w-full lg:w-1/3 min-w-[300px] lg:max-w-[800px] h-full driftinfast mr-0 lg:mr-[2rem] mb-[2rem] lg:mb-0'>
                  <img className='mb-[2rem] w-[95%] h-auto max-w-[400px]' src={require('../images/projectstitle.png')} alt='projects'/>
                  <p className='text-blue text-justify'>
                      As soon as I learned the basics of web development the summer of 2022, I began to take on projects where I could apply and develop my skills.<br/><br/>
                      Throughout my experiences I have learned to use: <br/>
                      <div className='ml-[1rem] text-start'>
                          <mark>Figma, Adobe Illustrator, & Adobe Photoshop</mark> for designs,<br/>
                          <mark>Tailwind & Bootstrap CSS frameworks</mark> for front-end web/app development,<br/>
                          <mark>MySQL/Firebase, PHP, ExpressJs and Axios</mark> for back-end data management, and<br/>
                          <mark>ReactJs & React Native</mark> to bring it all together.
                      </div>
                      <div className='flex flex-row flex-wrap w-full h-fit mt-[1rem]'>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/Adobe_Photoshop_CC_icon.svg.png')} alt='photoshop'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/Adobe_Illustrator_CC_icon.svg.png')} alt='illustrator'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/1667px-Figma-logo.svg.png')} alt='figma'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/html.png')} alt='html'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/css.png')} alt='css'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/javascript.png')} alt='javascript'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/php-icon-png-0.png')} alt='php'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/tailwind.png')} alt='tailwind'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/bootstrap-logo-shadow.png')} alt='bootstrap'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/react-icon-in-a-hexagon-vector-36587857.png')} alt='react'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/1443992.png')} alt='mysql'/>
                        <img className='w-[4rem] aspect-square object-contain m-[0.25rem]' src={require('../images/icons/1611674.png')} alt='firebase'/>
                      </div>
                  </p>
              </div>

              <div className='justify-start w-full lg:w-1/3 min-w-fit lg:max-w-[800px] h-fit'>
                  <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects/olympus'}>
                    <div className='w-full flex flex-col justify-start items-start'>
                      <div className='flex flex-col sm:flex-row space-x-[1rem] items-start sm:items-center w-full'>
                        <Heading>
                          <div className='text-blue keplersemi text-[24px]'>Project Olympus</div>
                        </Heading>
                        <div className='keplermed whitespace-nowrap'>Oct 2022 ~ Aug 2023</div>
                      </div>
                      <div className='tags flex w-full h-fit p-[0.5rem] justify-start items-start flex-wrap'>
                        <Tag text={'Branding'}/>
                        <Tag text={'Web Design'}/>
                        <Tag text={'UI/UX Design'}/>
                        <Tag text={'Website Development'}/>
                        <Tag text={'App Development'}/>
                      </div>
                    </div>
                  </Button>
                  
                  <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects/nuft'}>
                    <div className='w-full flex flex-col justify-start items-start'>
                      <div className='flex flex-col sm:flex-row space-x-[1rem] items-start sm:items-center w-full'>
                        <Heading>
                          <div className='text-blue keplersemi text-[24px]'>Northwestern Fintech Club</div>
                        </Heading>
                        <div className='keplermed whitespace-nowrap'>Sep 2022 ~ Jan 2023</div>
                      </div>
                      <div className='tags flex w-full h-fit p-[0.5rem] justify-start items-start flex-wrap'>
                        <Tag text={'Branding'}/>
                        <Tag text={'Web Design'}/>
                        <Tag text={'UI/UX Design'}/>
                        <Tag text={'Website Development'}/>
                      </div>
                    </div>
                  </Button>

                  <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects/spacebars'}>
                    <div className='w-full flex flex-col justify-start items-start'>
                      <div className='flex flex-col sm:flex-row space-x-[1rem] items-start sm:items-center w-full'>
                        <Heading>
                          <div className='text-blue keplersemi text-[24px]'>Space Bars</div>
                        </Heading>
                        <div className='keplermed whitespace-nowrap'>Sep 2023 ~ Sep 2023</div>
                      </div>
                      <div className='tags flex w-full h-fit p-[0.5rem] justify-start items-start flex-wrap'>
                        <Tag text={'Game Development'}/>
                        <Tag text={'UI/UX Design'}/>
                        <Tag text={'Web Design'}/>
                      </div>
                    </div>
                  </Button>

                  <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects/chatapp'}>
                    <div className='w-full flex flex-col justify-start items-start'>
                      <div className='flex flex-col sm:flex-row space-x-[1rem] items-start sm:items-center w-full'>
                        <Heading>
                          <div className='text-blue keplersemi text-[24px]'>Chatapp</div>
                        </Heading>
                        <div className='keplermed whitespace-nowrap'>Aug 2023 ~ Oct 2023</div>
                      </div>
                      <div className='tags flex w-full h-fit p-[0.5rem] justify-start items-start flex-wrap'>
                        <Tag text={'Web App Development'}/>
                        <Tag text={'Backend Data Management'}/>
                        <Tag text={'UI/UX Design'}/>
                        <Tag text={'Web Design'}/>
                      </div>
                    </div>
                  </Button>
                  
                  <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects/portfolio'}>
                    <div className='w-full flex flex-col justify-start items-start'>
                      <div className='flex flex-col sm:flex-row space-x-[1rem] items-start sm:items-center w-full'>
                        <Heading>
                          <div className='text-blue keplersemi text-[24px]'>Personal Portfolio Website</div>
                        </Heading>
                        <div className='keplermed whitespace-nowrap'>Jun 2023 ~ Sep 2023</div>
                      </div>
                      <div className='flex w-full h-fit p-[0.5rem] justify-start items-start flex-wrap'>
                        <Tag text={'Branding'}/>
                        <Tag text={'Web Design'}/>
                        <Tag text={'Website Development'}/>
                      </div>
                    </div>
                  </Button>
              </div>
          </div>
        </div>

        <Headerbar {... props}/>
    </div>
  )
}