import React, { useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'

export default function Cursor(props) {
    const [mouseY, setMouseY] = useState(0);
    const [mouseX, setMouseX] = useState(0);
    const [topLeft, setTopLeft] = useState([0,0]);
    const [bottomRight, setBottomRight] = useState([0,0]);

    const [mouseDown, setMouseDown] = useState(false);

    useEffect(()=>{    
        const handleMouseDown = () => {
            setMouseDown(true);
        }
    
        const handleMouseUp = () => {
            setMouseDown(false);
        }

        const setCoords = () => {
            if (Object.keys(props.hover).length === 0) {
                setTopLeft([mouseX, mouseY]);
                setBottomRight([mouseX, mouseY]);
            } else {
                setTopLeft([props.hover.positionX, props.hover.positionY]);
                setBottomRight([props.hover.positionX + props.hover.width, props.hover.positionY + props.hover.height]);
            }
        }

        const handleMouseMove = (e) => {
            if (e) {
                setMouseX(e.clientX);
                setMouseY(e.clientY);
                setCoords();
            }
        }

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
              };
    }, [mouseX, mouseY, props.hover, props.ReducedMotion]);

    const positionSpring = useSpring({
        width: `${topLeft[0] && bottomRight[0] ? bottomRight[0]-topLeft[0] : 0}px`,
        height: `${topLeft[1] && bottomRight[1] ? bottomRight[1]-topLeft[1] : 0}px`,
        borderWidth: mouseDown ? '3px' : '1px',
        padding: mouseDown ? '1px' : '3px',
        opacity: (Object.keys(props.hover).length === 0) ? '0%' : '100%',
        transform: `translate(${topLeft[0] ? topLeft[0] : 0}px, ${topLeft[1] ? topLeft[1] : 0}px)`,
        config: {
            mass: props.ReducedMotion ? 0 : (Object.keys(props.hover).length === 0 ? 0 : 0.1),
            tension: 400,
            friction: 20,
        }
    })
    
    const cursorSpring = useSpring({
        opacity: mouseDown ? '100%' : '50%',
        config: {
            mass: 0,
        }
    })

    // console.log('cursor', props.hover.width, props.hover.height, props.hover.positionX, props.hover.positionY);
    
    return (
        <div className='fixed z-40 locked w-full h-full'>
            <animated.div className='border-blue absolute flex justify-center items-center locked' style={positionSpring}>
                <animated.div className='border-blue border-[1px] w-[100%] h-[100%] locked' style={cursorSpring}/>
            </animated.div>
        </div>
    )
}
