import React, { useState, useEffect, useRef } from 'react';

import Tag from '../components/Tag';
import Button from '../components/Button';

import Headerbar from './Headerbar'

function NUFT(props) {
  const bannerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const Row = (props) => {
    return(<div className='border-t-[1px] border-blue p-[0.5rem] w-full h-fit keplersemi flex flex-row space-x-[0.5rem]'>
      {props.children}
    </div>)
  }

  useEffect(() => {
    const bannerHeight = () => {
      if (bannerRef.current && bannerRef.current.getBoundingClientRect().height !== height) {
        let h = bannerRef.current.getBoundingClientRect().height;
        setHeight(h)
      }
    }

    bannerHeight();
    window.addEventListener('resize', bannerHeight);
    setInterval(()=>{
      bannerHeight();
    }, 500)

    return () => {
      window.removeEventListener('resize', bannerHeight);
    }
  }, []);

    return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full text-blue pt-[4.5rem]'
      onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto w-full h-fit lg:max-h-[1000px] p-[1.5rem] md:p-[3rem] xl:px-[6rem] relative z-0 max-w-[2000px]'>
        <div className='min-h-fit h-full w-full flex flex-col md:flex-row items-start justify-start pb-[2rem] md:pb-0'>
          <div id='Banner' ref={bannerRef} className='w-full lg:w-1/2 xl:w-1/3 3xl:min-w-[500px] h-fit mb-[2rem] md:mb-0 md:mr-[1rem] fadein'>
            <div className='lg:max-w-[800px] driftinfast relative left-0 flex flex-col justify-start items-start h-fit min-h-full'>
                <a className='keplersemi text-blue w-fit inline md:hidden mb-[2rem] h-fit' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
                </a>

              <div className='w-full h-fit flex flex-col justify-start space-y-[1rem]'>
                <div className='w-full h-fit'>
                  <img className='mb-[1rem] w-[100%] h-auto max-w-[600px]' src={require('../images/projects/nuft/title.png')} alt='northwestern fintech'/>
                  <div className='flex w-full h-fit py-[0.5rem] justify-start flex-wrap'>
                    <Tag text={'Branding'}/>
                    <Tag text={'Web Design'}/>
                    <Tag text={'UI/UX Design'}/>
                    <Tag text={'Website Development'}/>
                  </div>
                </div>

                <div className='w-full h-fit flex justify-start items-start'>
                  <p className='min-w-[300px] text-justify'>
                    The <mark>Northwestern Financial Tech Club</mark> is a student organization that pursues various projects related to
                    quant finance. I managed a project with 4 other members to <mark>redesign and build the club's home page.</mark> Our team created a fresh <mark>visual identity</mark> for the
                    club, applied the visuals to <mark>website mockups</mark>, and coded it collaboratively using Git.<br/><br/>I designed/built the front page and 
                    organized regular virtual team check-ins to gather feedback and resolve merge conflicts. Revisiting the product after refining my design & mockup skills, 
                    I also put together a potential <mark>v3 redesign</mark> and a <mark>figma component library</mark> that future developers could use.
                  </p>
                </div>

                <div className='w-full h-fit text-blue keplersemi'>
                  <div className='flex w-full h-fit pt-0 justify-start items-start flex-col text-blue keplermed'>
                    <Row>
                      <div className='min-w-[8rem]'>DURATION</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>Sep 2022 ~ Jan 2023</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='min-w-[8rem]'>ROLES</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>1) Web Development Project Leader</p>
                        <p>2) Chief Visual Brand Designer</p>
                        <p>3) Web Designer & Developer</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='min-w-[8rem]'>SKILLS<br/>DEVELOPED</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>1) Wireframing websites on Figma</p>
                        <p>2) Brainstorming & planning brand visual identity</p>
                        <p>3) Web development using TailwindCSS & React Spring</p>
                        <p>4) Remote developer collaboration on Github</p>
                      </div>
                    </Row>
                  </div>
                </div>
                
                <a className='keplersemi text-blue w-fit h-fit hidden md:inline' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
                </a>
              </div>
            </div>
          </div>

          <div id='scroll' className='relative w-full lg:w-1/2 xl:w-2/3 3xl:w-full flex flex-row z-0 justify-start items-start pb-[2rem] md:pb-[1rem] md:pl-[1rem]' 
            style={{height: (window.innerWidth > 768) ? height : 'fit'}}>
            <img className='driftinleft hidden md:inline absolute -z-10 w-[100%] h-[100%] object-cover right-[1.5rem] top-[1rem] pl-[1.5rem] pb-[1rem] opacity-[50%]' src={require('../images/nuftbg.png')} alt='gradient background'/>

            <div className='w-full h-fit md:h-full flex flex-col space-y-[0.5rem] justify-start items-start md:overflow-y-scroll'>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  FIGMA MOCKUP
                </div>
              </div>
              {/* <Heading>
                <div className='keplersemi text-blue my-[1rem] ml-[10px]'>FIGMA MOCKUP</div>
              </Heading> */}
              
              <img className='w-full h-auto object-top' src={require('../images/projects/nuft/figma_1.png')} alt='fintech figma'/>
              
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  WEBSITE SCREENSHOTS
                </div>
              </div>
              {/* <Heading>
                <div className='keplersemi text-blue my-[1rem] ml-[10px]'>WEBSITE SCREENSHOTS</div>
              </Heading> */}
              <img className='w-full h-auto object-contain' src={require('../images/projects/nuft/screenshot_1.png')} alt='fintech website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/nuft/screenshot_2.png')} alt='fintech website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/nuft/screenshot_3.png')} alt='fintech website screenshot'/>
              <img className='w-full h-auto object-contain' src={require('../images/projects/nuft/screenshot_4.png')} alt='fintech website screenshot'/>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  WEBSITE V3 MOCKUP
                </div>
              </div>
              {/* <Heading>
                <div className='keplersemi text-blue my-[1rem] ml-[10px]'>WEBSITE V3 FIGMA MOCKUP</div>
              </Heading> */}
              
              <img className='w-full h-auto object-top' src={require('../images/projects/nuft/v3figma_2.png')} alt='website v3 screenshot'/>
              
              <img className='w-full h-auto object-contain' src={require('../images/projects/nuft/v3figma_3.png')} alt='website v3 screenshot'/>

              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  FIGMA COMPONENT LIBRARY
                </div>
              </div>
              <img className='w-full h-auto object-contain p-[1rem] bg-dark' src={require('../images/projects/nuft/v3figma_1.png')} alt='website v3 screenshot'/>
              <a className='keplersemi text-blue w-fit lg:hidden my-[2rem]' href='/projects'>
              <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Headerbar {... props}/>
    </div> );
}

export default NUFT;