import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import {isMobile} from 'react-device-detect';

import Button from './Button'
import Heading from './Heading'
import EtcCard from './EtcCard'
import DigitalArtCard from './DigitalArtCard';

export default function Menu(props) {
  const [menuActive, setMenuActive] = useState(false);

  const menuItemsSpring = useSpring({
    width: menuActive ? '100%' : '0%',
    config: {      
      mass: props.ReducedMotion ? 0 : 0.5,
    }
  })

  const menuSpring = useSpring({
    opacity: menuActive ? '85%' : '0%',
    width: menuActive ? '100%' : '0%',
    config: {
      mass: props.ReducedMotion ? 0 : 0.5,
      tension: 210,
      friction: 15,
    }
  })

  return (
    <div className='relative h-full keplersemi text-blue flex w-[5rem]'>
      <animated.div className='fixed -z-10 w-screen h-screen right-0 top-0 bg-white' style={menuSpring}/>
      <div className='fixed z-50 flex justify-end items-start top-0 right-0' style={{height: menuActive ? '100%' : '5rem', width:menuActive ? '100%':'fit'}}>
        <div className='pt-[1.5rem] px-[1.5rem]' onClick={()=>{setMenuActive(!menuActive)}}>
          <Button setHover={props.setHover}  ReducedMotion = {props.ReducedMotion}>
            {
              menuActive ?
              <img className='object-contain w-[1.5rem] aspect-square' src={require('../images/close.png')} alt='close'/> : 'MENU'
            }
          </Button>
        </div>
        <div className='fixed overflow-hidden w-full h-full overflow-y-scroll flex whitespace-nowrap p-[1.5rem] pt-[3rem] space-x-[1rem] justify-start flex-col items-end right-0'
          style={{display: menuActive ? 'inline' : 'none'}}>
            <animated.div className='overflow-hidden right-0 flex flex-col py-[1rem] min-h-[700px] items-center justify-center' style={menuItemsSpring}>
              <div className='w-full h-full flex flex-col justify-center items-start space-y-[1rem] max-w-[50rem] overflow-hidden'>
                <Heading>
                    <Button setHover={props.setHover} link={'/'}>
                        <div className='text-blue keplersemi text-[24px]'>HOME</div>
                    </Button>
                </Heading>
                <div className='flex flex-col sm:flex-row w-full space-y-[1rem] sm:space-y-0 sm:space-x-[2rem]'>
                  <Heading>
                      <Button setHover={props.setHover} link={'/projects'}>
                          <div className='text-blue keplersemi text-[24px]'>PROJECTS</div>
                      </Button>
                  </Heading>
                  <Heading>
                      <Button setHover={props.setHover} link={'/digitalart'}>
                          <div className='text-blue keplersemi text-[24px]'>DIGITAL ART</div>
                      </Button>
                  </Heading>
                  {/* <DigitalArtCard {... props}/> */}
                </div>
                <div>
                  <EtcCard ReducedMotion = {props.ReducedMotion} setHover={props.setHover}/>
                </div>
                <div className='border-y-[3px] w-full py-[4px] border-blue'>
                  <div className='flex flex-row justify-between flex-wrap border-y-[1px] border-blue w-full py-[1rem]
                                  text-blue keplersemi'>
                    <Button  ReducedMotion = {props.ReducedMotion} link={'https://www.linkedin.com/in/tom-hojun-choi/'} download={false}
                    setHover={props.setHover}>
                      <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/linkedin.png')} alt='linkedin icon'/>
                      <div>LINKEDIN</div>
                    </Button>
                    <Button ReducedMotion = {props.ReducedMotion} link={'https://instagram.com/artbyhunch'} download={false}
                    setHover={props.setHover}>
                      <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/instagram.png')} alt='instagram icon'/>
                      <div>INSTAGRAM</div>
                    </Button>
                    <Button ReducedMotion = {props.ReducedMotion} link={'mailto:tomchoi2025@u.northwestern.edu'} download={false}
                    setHover={props.setHover}>
                      <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/mail.png')} alt='email me'/>
                      <div>EMAIL</div>
                    </Button>
                    <Button ReducedMotion = {props.ReducedMotion} link={require('../components/tomchoiresume.pdf')} download={true}
                    setHover={props.setHover}>
                      <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/download.png')} alt='download resume'/>
                      <div>RESUME</div>
                    </Button>
                  </div>
                </div>
                {
                  !isMobile
                  &&
                  <div className='flex'>
                  <div className='keplermed text-blue w-fit cursor-pointer relative z-50' onClick={props.toggleRM}>
                    <Button setHover={props.setHover}>
                      <div className='flex flex-row h-[1rem] justify-start items-center'>
                        <div className='p-[1px] w-[1rem] aspect-square border-blue border-[1px] mr-[0.5rem]'>
                          <div className='w-full h-full border-[1px] border-blue'
                            style={{backgroundColor: props.ReducedMotion ? 'rgba(42, 52, 151, 1)' : 'rgba(42, 52, 151, 0)', opacity: props.ReducedMotion ? 1 : 0.5}}/>
                        </div>
                        {`Reduced Motion: ${props.ReducedMotion ? 'ON' : 'OFF'}`}
                      </div>
                    </Button>
                  </div>
                  <div className='keplermed text-blue w-fit cursor-pointer relative z-50' onClick={()=>{props.toggleTextures();}}>
                    <Button setHover={props.setHover}>
                      <div className='flex flex-row h-[1rem] justify-start items-center'>
                        <div className='p-[1px] w-[1rem] aspect-square border-blue border-[1px] mr-[0.5rem]'>
                          <div className='w-full h-full border-[1px] border-blue'
                            style={{backgroundColor: !props.texturesOff ? 'rgba(42, 52, 151, 1)' : 'rgba(42, 52, 151, 0)', opacity: !props.texturesOff ? 1 : 0.5}}/>
                        </div>
                        {`Textures: ${props.texturesOff ? 'OFF' : 'ON'}`}
                      </div>
                    </Button>
                  </div>
                  </div>
                }

              </div>
            </animated.div>
        </div>
      </div>
    </div>
  )
}
