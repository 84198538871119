import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Waveform } from '@uiball/loaders';

import getSongItem from "./SpotifyAPI";
import Button from './Button';

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const refreshToken = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN;

function Spotify(props) {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState({});
    const [hover, setHover] = useState(false);

    useEffect(() => {
      const refreshSpotifyThings = () => {
        Promise.all([
          getSongItem(
            clientId,
            clientSecret,
            refreshToken
          ),
        ]).then((results) => {
          setResult(results[0]);
          setLoading(false);
        });
      }

      refreshSpotifyThings();

      const i = setInterval(() => {
        refreshSpotifyThings();
      }, 5000);


      return () => clearInterval(i);    
    }, []);

    const roundTextSpring = useSpring({
      opacity: hover ? 0.3 : 1,
    })

    const descriptionSpring = useSpring({
      opacity: hover ? 1 : 0,
    })

    return (
      <div className='relative text-white w-[12rem] 3xl:w-[14rem] aspect-square flex justify-center items-center p-[1.2rem]'>
          <animated.img className='w-full aspect-square object-contain rotate absolute -z-10 rounded-full' src={require('../images/listening.png')} alt='currently listening circle text'
            style={roundTextSpring}/>
          {
            loading
            ? <div className='bg-blue w-full aspect-square rounded-full flex flex-col justify-center items-center'>
              <Waveform size={30} color={'#FBF3EB'}/>
              <p className='text-white keplermed mt-[1rem]'>Loading...</p>
            </div>
            :<div className='border-blue border-[1px] rounded-full w-full aspect-square bg-white'
              onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
              <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={result.songUrl}>
                <animated.div className='w-full flex flex-col justify-center items-center text-blue aspect-square rounded-full bg-white bg-opacity-90 absolute left-0 top-0'
                  style={descriptionSpring}>
                    <p className='text-center keplersemi w-[80%] whitespace-pre-wrap'>{result.title}</p>
                    <p className='text-center keplermed text-[10px] w-2/3 whitespace-pre-wrap'>{result.artist}</p>
                </animated.div>
                <img className='object-fill w-full aspect-square rounded-full' src={result.albumImageUrl} alt={result.title}/>
              </Button>
            </div>
          }
          <div className='absolute -z-20 w-[120%] h-[120%] rounded-full bg-white blur-xl'/>
      </div>
    );
}

export default Spotify;

