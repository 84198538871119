import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import full_wawh from '../images/art/waves&whiskers.png';
import full_fofu from '../images/art/form+function.png';
import full_dct from '../images/art/dreamscometrue.png';
import full_exp from '../images/art/explore.png';
import full_xsub from '../images/art/extrasubstance.png';
import full_ftwo from '../images/art/formulatwo.png';
import full_idfn from '../images/art/ildolcefarniente.png';
import full_nbdm from '../images/art/nobodymove.png';
import full_mlsp from '../images/art/moonlightsupermarket.png';
import full_bfre from '../images/art/brainfreeze.png';
import full_brth from '../images/art/breather.png';

import Headerbar from './Headerbar';
import Button from '../components/Button';
import Tag from '../components/Tag';

function Piece(props) {
    var pieceName;
    let navigate = useNavigate();
    const [pieceTitle, setPieceTitle] = useState('');
    const [nextTitle, setNextTitle] = useState('');
    const [prevTitle, setPrevTitle] = useState('');
    const [position, setPosition] = useState(0);
    const [pieceLink, setPieceLink] = useState('');
    const [pieceDate, setPieceDate] = useState('');
    const [pieceTags, setPieceTags] = useState([]);
    const [piecePost, setPiecePost] = useState('');
    const [pieceReel, setPieceReel] = useState('');

    const [next, setNext] = useState('');
    const [prev, setPrev] = useState('');

    const pieceDict = {
        'wawh':{position: 1, title:'Waves & Whiskers', link: full_wawh, date:'9.5.2023', tags:['Procreate'], post:'https://www.instagram.com/p/CwzOw9arnDg/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CwzKIRLL-Rk/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'fofu':{position: 3, title:'Form + Function', link: full_fofu, date:'6.25.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Ct6f4L5rXFc/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Ct6drHdJieh/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'xsub':{position: 2, title:'Extra Substance', link:full_xsub, date:'10.5.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Cx-6Ch5yIHd/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Cx-4D6LStOu/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'dct':{position: 4, title:'Dreams Come True', link:full_dct, date:'7.23.2023', tags:['Procreate', 'Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/CvCg_9ZrvgN/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CvCfzfGtS_f/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'exp':{position: 7, title:'Explore', link:full_exp, date:'8.15.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/Cv9uM3NL_hR/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/Cv9s92ltVNL/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='}, 
        'ftwo':{position: 5, title:'Formula 2', link:full_ftwo, date:'9.21.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/CxdJCMDr68r/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
        'brth':{position: 6, title:'Breather', link:full_brth, date:'10.9.2023', tags:['Procreate', 'Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/CyLI69dyoNR/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CyJQkxSSuF7/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'idfn':{position: 9, title:'Il Dolce Far Niente', link:full_idfn, date:'5.1.2023', tags:['Procreate'], post:'https://www.instagram.com/p/CrwFG1Mu1dw/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:'https://www.instagram.com/reel/CrrKyGAsm8K/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
        'nbdm':{position: 10, title:'Nobody Move!', link:full_nbdm, date:'9.5.23', tags:['Photoshop', 'Illustrator'], post:'https://www.instagram.com/p/Cwz9_3Crwp1/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
        'bfre':{position: 8, title:'Brain Freeze', link:full_bfre, date:'8.11.2023', tags:['Procreate', 'Photoshop'], post:'https://www.instagram.com/p/CvxPFfFLSpk/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==', reel:''},
        'mlsp':{position: 11, title:'Moonlight Supermarket', link:full_mlsp, date:'3.20.2023', tags:['Procreate'], post:'', reel:'https://www.instagram.com/reel/CqAYTZluLCz/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='},
    }

    var PieceList = [
        'wawh',
        'xsub',
        'fofu',
        'dct',
        'ftwo',
        'brth',
        'exp',
        'bfre',
        'idfn',
        'nbdm',
        'mlsp',
    ]    
    useEffect(() => {

        let res = window.location.pathname;

        if (res === null) {
            window.location.pathname = '/digitalart';
        } else {
            pieceName = res.slice(12);
            setPieceTitle(pieceDict[pieceName].title);
            setPosition(pieceDict[pieceName].position);
            setPieceLink(pieceDict[pieceName].link);
            setPieceDate(pieceDict[pieceName].date);
            setPieceTags(pieceDict[pieceName].tags);
            setPiecePost(pieceDict[pieceName].post);
            setPieceReel(pieceDict[pieceName].reel);

            if (pieceDict[pieceName].position === 1) {
                setPrev(PieceList[PieceList.length - 1]);
                setPrevTitle(pieceDict[PieceList[PieceList.length - 1]].title);
            } else {
                setPrev(PieceList[pieceDict[pieceName].position - 2]);
                setPrevTitle(pieceDict[PieceList[pieceDict[pieceName].position - 2]].title);
            }
            if (pieceDict[pieceName].position === 11) {
                setNext(PieceList[0]);
                setNextTitle(pieceDict[PieceList[0]].title);
            } else {
                setNext(PieceList[pieceDict[pieceName].position]);
                setNextTitle(pieceDict[PieceList[pieceDict[pieceName].position]].title);
            }
        }
    }, [pieceName]);

    return ( 
    <div className='relative w-full flex flex-col justify-start items-center min-h-[600px] h-full text-blue pt-[4.5rem] bg-white'
        onMouseEnter={() => props.setHover({})}>
        <div className='flex my-auto justify-center items-center w-full h-fit p-[1.5rem] md:px-[3rem] lg:px-[6rem] relative z-0 max-w-[2000px]'>
          <div className='h-fit w-full flex flex-col items-start justify-start relative'>
            <div id='banner' className='text-blue keplersemi w-full h-fit flex flex-row justify-center items-start mb-[1rem]'>
                <div className='h-full flex flex-col items-start justify-start w-full'>
                    <div className='keplersemi text-blue w-fit md:hidden' onClick={()=>navigate('/digitalart', {state:{ focuson: position }})}>
                        <Button {... props}>
                            <div className='flex flex-row justify-start items-center'>
                                <img className='h-[1rem] aspect-square mr-[0.5rem]' src={require('../images/dropdown.png')} alt='arrow'/>
                                <div className='pt-[2px]'>{'Back to Gallery'}</div>
                            </div>
                        </Button>
                    </div>
                    <div className='w-full keplerblack text-center uppercase' style={{fontSize:'min(8vw, 64px)'}}>{pieceTitle}</div>
                    <div className='w-full h-fit border-y-[3px] py-[4px] border-blue'>
                        <div className='border-y-[1px] border-blue relative w-full flex flex-col md:flex-row justify-between lg:justify-center items-center py-[1rem]'>
                            <div className='hidden md:inline lg:absolute left-0'>
                                <div className='keplersemi text-blue w-fit' onClick={()=>navigate('/digitalart', {state:{ focuson: position }})}>
                                    <Button {... props}>
                                        <div className='flex flex-row justify-start items-center'>
                                            <img className='h-[1rem] aspect-square mr-[0.5rem]' src={require('../images/dropdown.png')} alt='arrow'/>
                                            <div className='pt-[2px]'>{'Back to Gallery'}</div>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                            <div className='w-fit flex flex-row justify-center items-center pr-[0.5rem]'>
                                <div className='flex w-fit h-fit justify-start items-start flex-wrap mr-[0.5rem]'>
                                {
                                pieceTags.map((tag)=>{return(<Tag text={tag}/>)})
                                }
                                </div>
                                {pieceDate}
                            </div>
                            <div className='lg:absolute right-0 w-fit flex flex-row justify-center items-start'>
                                {
                                    piecePost !== ''
                                    &&
                                    <Button {... props} link={piecePost}>
                                        <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/link.png')} alt='visit website'/>
                                        <div>VIEW POST</div>
                                    </Button>
                                }
                                {
                                    pieceReel !== ''
                                    &&
                                    <Button {... props} link={pieceReel}>
                                        <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/link.png')} alt='visit website'/>
                                        <div>WATCH REEL</div>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-fit w-full relative flex flex-row'>
                <img className='w-full h-auto driftinslow' src={pieceLink} alt={pieceTitle}/>
            </div>

            <div id='bottom nav' className='flex flex-row w-full h-fit keplersemi mt-[1rem]'>
                <div className='w-1/2 h-fit'>
                    <Button {... props} link={`/digitalart/${prev}`}>
                        <div className='flex flex-col justify-center items-end'>
                            <div className='flex justify-end items-center'>
                                <img className='h-[1rem] aspect-square mr-[0.5rem]' src={require('../images/dropdown.png')} alt='arrow'/>
                                PREV. PIECE
                            </div>
                            <div>{prevTitle}</div>
                        </div>
                    </Button>
                </div>
                <div className='w-1/2 h-fit'>
                    <Button {... props} link={`/digitalart/${next}`}>
                        <div className='flex flex-col justify-center items-start'>
                            <div className='flex justify-start items-center'>
                                NEXT PIECE
                                <img className='rotate-180 h-[1rem] aspect-square ml-[0.5rem]' src={require('../images/dropdown.png')} alt='arrow'/>
                            </div>
                            <div>{nextTitle}</div>
                        </div>
                    </Button>
                </div>
            </div>
          </div>
        </div>

      <Headerbar {... props}/>
      </div>
  
    );
}

export default Piece;