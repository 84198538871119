import React from 'react'

import Heading from './Heading';
import Button from './Button';
import ListElement from './ListElement';

export default function ProjectCard(props) {
  return (
    <div className='w-full h-full mb-[1rem]'>
        <Heading>
            <Button ReducedMotion = {props.ReducedMotion} setHover={props.setHover} link={'/projects'}>
                <div className='text-blue keplersemi text-[24px]'>PROJECTS</div>
            </Button>
        </Heading>
        <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Project Olympus'} link='/projects/olympus'/>
        <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Northwestern Financial Tech Club'} link='/projects/nuft'/>
        <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Space Bars (Game)'} link='/projects/spacebars'/>
        <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'Chatapp'} link='/projects/chatapp'/>
        <ListElement ReducedMotion = {props.ReducedMotion} setHover={props.setHover} text={'SEE MORE ...'} link='/projects'/>
    </div>
  )
}
