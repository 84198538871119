import React, { useState, useEffect, useRef } from 'react';

import Tag from '../components/Tag';
import Button from '../components/Button';

import Headerbar from './Headerbar'

function Chatapp(props) {
  const bannerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const Row = (props) => {
    return(<div className='border-t-[1px] border-blue p-[0.5rem] w-full h-fit keplersemi flex flex-row space-x-[0.5rem]'>
      {props.children}
    </div>)
  }

  useEffect(() => {
    const bannerHeight = () => {
      if (bannerRef.current && bannerRef.current.getBoundingClientRect().height !== height) {
        let h = bannerRef.current.getBoundingClientRect().height;
        setHeight(h)
      }
    }

    bannerHeight();
    window.addEventListener('resize', bannerHeight);
    setInterval(()=>{
      bannerHeight();
    }, 500)

    return () => {
      window.removeEventListener('resize', bannerHeight);
    }
  }, []);

    return (
    <div className='relative w-full flex flex-col justify-start items-center min-h-[840px] h-full text-blue pt-[4.5rem]'
      onMouseEnter={() => props.setHover({})}>
      <div className='flex my-auto w-full h-fit lg:max-h-[1000px] p-[1.5rem] md:p-[3rem] xl:px-[6rem] relative z-0 max-w-[2000px]'>
        <div className='min-h-fit h-full w-full flex flex-col md:flex-row items-start justify-start pb-[2rem] md:pb-0'>
          <div id='Banner' ref={bannerRef} className='w-full lg:w-1/2 xl:w-1/3 3xl:min-w-[500px] h-fit mb-[2rem] md:mb-0 md:mr-[1rem] fadein'>
            <div className='lg:max-w-[800px] driftinfast relative left-0 flex flex-col justify-start items-start h-fit min-h-full'>
                <a className='keplersemi text-blue w-fit inline md:hidden mb-[2rem] h-fit' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
                </a>

              <div className='w-full h-fit flex flex-col justify-start space-y-[1rem]'>
                <div className='w-full h-fit'>
                  <img className='mb-[1rem] w-[75%] h-auto max-w-[450px]' src={require('../images/projects/chatapp/title.png')} alt='chatapp'/>
                  <div className='flex w-full h-fit py-[0.5rem] justify-start flex-wrap'>
                    <Tag text={'Web App Development'}/>
                    <Tag text={'Backend Data Management'}/>
                    <Tag text={'UI/UX Design'}/>
                    <Tag text={'Web Design'}/>
                  </div>
                </div>

                <div className='w-full h-fit flex justify-start items-start'>
                  <p className='min-w-[300px] text-justify'>
                    I applied my learning in backend development to a <mark>chatting web app</mark> using my own Linux server, with features like <mark>user login/signup, session management, and chatrooms.</mark> I familiarized myself with building <mark>ExpressJs</mark> functions that make <mark>MySQL</mark> queries to my backend, which I also
                    designed the architecture for. As my first time building a relational database, it was a fun challenge that got me thinking of new creative ways to navigate data structures.
                    <br/><br/>On the frontend, I experimented with a <mark>neo-brutalist design scheme</mark> to build my UI elements. I created a <mark>friendly tone</mark> with primary colors and a rounded geometric font, while keeping things neat and organized following 
                    layouts I observed on other chatapps. 
                  </p>
                </div>

                <div className='w-full h-fit text-blue keplersemi'>
                  <div className='flex w-full h-fit pt-0 justify-start items-start flex-col text-blue keplermed'>
                    <Row>
                      <div className='min-w-[8rem]'>DURATION</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>Aug 2023 ~ Oct 2023</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='min-w-[8rem]'>SKILLS<br/>DEVELOPED</div>
                      <div className='flex flex-col justify-start items-start keplermed'>
                        <p>1) Planning data architecture</p>
                        <p>2) CRUD using ExpressJs & MySQL</p>
                        <p>3) Neo-brutalist web design and development</p>
                        <p>4) Debugging and server management using PM2</p>
                      </div>
                    </Row>
                  </div>
                </div>

                <div className='flex flex-wrap w-full keplersemi justify-start items-center'>
                <Button ReducedMotion = {props.ReducedMotion} link={'https://github.com/choi-choi-train/chatapp'} download={true}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/github.png')} alt='github repository'/>
                    <div>GITHUB (FRONTEND)</div>
                  </Button>
                  <Button ReducedMotion = {props.ReducedMotion} link={'https://github.com/choi-choi-train/chatappbackend'} download={true}
                  setHover={props.setHover}>
                    <img className='h-[1rem] mr-[0.5rem] aspect-square object-contain' src={require('../images/github.png')} alt='github repository'/>
                    <div>GITHUB (BACKEND)</div>
                  </Button>
                </div>
                
                <a className='keplersemi text-blue w-fit h-fit hidden md:inline' href='/projects'>
                <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
                </a>
              </div>
            </div>
          </div>

          <div id='scroll' className='relative w-full lg:w-1/2 xl:w-2/3 3xl:w-full flex flex-row z-0 justify-start items-start pb-[2rem] md:pb-[1rem] md:pl-[1rem]' 
            style={{height: (window.innerWidth > 768) ? height : 'fit'}}>
            <img className='driftinleft hidden md:inline absolute -z-10 w-[100%] h-[100%] object-cover right-[1.5rem] top-[1rem] pl-[1.5rem] pb-[1rem] opacity-[50%]' src={require('../images/chatappbg.png')} alt='gradient background'/>

            <div className='w-full h-fit md:h-full flex flex-col space-y-[0.5rem] justify-start items-start md:overflow-y-scroll'>
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  SIGNUP PAGE
                </div>
              </div>
              
              <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/signup.mp4')} alt='chatapp signup'
                controls='on' autoplay='true' loop='true'/>
              
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  LOGIN
                </div>
              </div>

              <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/login.mp4')} alt='chatapp login'
                controls='on' autoplay='true' loop='true'/>
              
              <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  ROOM-BASED CHATTING
                </div>
              </div>

              <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/opening.mp4')} alt='chatapp room opening'
                controls='on' autoplay='true' loop='true'/>
                <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/deleting.mp4')} alt='chatapp room deleting'
                controls='on' autoplay='true' loop='true'/>

                <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  RANDOMIZING CHATROOMS 
                </div>
              </div>

              <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/random.mp4')} alt='chatapp random room'
                controls='on' autoplay='true' loop='true'/>

                <div className='border-y-[3px] border-blue py-[4px] w-full md:sticky top-0'>
                <div className='border-y-[1px] border-blue flex w-full px-[0.5rem] keplersemi py-[0.5rem] bg-white'>
                  LOGOUT
                </div>
              </div>

              <video className='w-full h-auto object-top' src={require('../images/projects/chatapp/logout.mp4')} alt='chatapp logout function'
                controls='on' autoplay='true' loop='true'/>

              <a className='keplersemi text-blue w-fit lg:hidden my-[2rem]' href='/projects'>
              <Button {... props}>
                  <div className='flex flex-row space-x-[0.5rem] justify-start items-center'>
                    <img className='h-[1rem] aspect-square' src={require('../images/dropdown.png')} alt='arrow'/>
                    <div className='pt-[3px]'>{'Back to Projects'}</div>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Headerbar {... props}/>
    </div> );
}

export default Chatapp;